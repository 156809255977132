<template>
    <Skeleton v-if="loading" width="100%" height="2rem" class="p-mt-2" />
	<div v-else>
        <Panel v-for="(audit, index) in audits" :key="index + 'audit'" :toggleable="true" :collapsed="true" class="p-mt-2">
            <template #header>
                <div class="p-panel-title">
                    <i v-tooltip="'All conditions met successfully when audited'" v-if="displayAuditStatus(audit) === 'ok' " class="o-tag far fa-check-circle ok circle" />
                    <i v-tooltip="'Some conditions failed when audited'" v-if="displayAuditStatus(audit) === 'warning' " class="o-tag far fa-check-circle warning circle" />
                    <i v-tooltip="'All conditions failed when audited'" v-if="displayAuditStatus(audit) === 'alert' " class="o-tag far fa-check-circle alert circle" />
                    Audit - {{audit.audit_id.replace(/.*\//g, '') + ' (' + new Date(audit.timestamp.value).toLocaleString() + ')'}}
                </div>
            </template>
            <div v-for="(result, index) in audit.overall_result" :key="index + 'result'">
                <div v-for="(detail, index) in result.details" :key="index + 'detail'">
                    <div v-if="detail.locale === $i18n.locale.toUpperCase()">
                        <div class="o-text-xlg">
                            {{detail.title}}
                        </div>
                        <span class="p-ml-2">
                            {{detail.text}}
                        </span>
                    </div>
                </div>

                <div v-for="(link, index) in result.document_links" :key="index + 'link'" class="p-ml-2">
                    <a v-if="link.locale === $i18n.locale.toUpperCase()" :href="link.URL" target="_blank">{{link.text}}</a>
                </div>
            </div>
            <div>
                <div class="o-text-xlg p-mt-2">
                    {{$t('Metrics')}}
                </div>
                <div v-for="(metric, index) in audit.metrics" :key="index + 'metric'" class="p-ml-2 p-mb-2">
                    <div v-for="(name, index) in metric.name" :key="index + 'name'">
                        <div v-if="name.locale === $i18n.locale.toUpperCase()">
                            <div class="o-text-lg">
                                {{name.text}}
                            </div>
                        </div>
                    </div>
                    <div v-for="(description, index) in metric.description" :key="index + 'description'" class="p-ml-2">
                        <div v-if="description.locale === $i18n.locale.toUpperCase()">
                            <div>
                                {{description.text}}
                            </div>
                        </div>
                    </div>
                    <div class="p-ml-2">{{$t('Score')}} : {{metric.score}} / {{metric.required_score}} {{metric.score_uom}} <i :class="'o-tag far ' + (metric.score >= metric.required_score ? 'fa-check-circle ok circle' : 'fa-time-circle alert circle')" />
                    </div>
                </div>
            </div>
        </Panel>
	</div>
</template>

<script>
import ApiService from "../service/ApiService";
import ProductService from "../service/ProductService";

export default {
	props: {
		eventid: String,
	},
	data() {
		return {
            audits: [],
            loading: true,
		};
	},
    created() {
        this.ApiService = new ApiService();
        
        this.productService = new ProductService(this.$auth.accessToken);
        this.$auth.eventBus.$on("access_token_refreshed", () =>
            this.productService.updateToken(this.$auth.accessToken)
        );
        this.productService.getFromEp('audit_status', {po: this.eventid}).then(data => {
            this.audits = data;
            this.loading = false;
        });
    },
    destroyed(){
        this.$auth.eventBus.$off("access_token_refreshed");
    },
	methods: {
        displayAuditStatus(a) {
            let success = false;
            let fail = false;


            a.metrics.forEach((m) => {
                if (m.score >= m.required_score){
                    success = true;
                }else {
                    fail = true;
                }
            });

            if (success && fail) {
                return 'warning';
            }
            else if (success) {
                return 'ok';
            }
            else if (fail) {
                return 'alert';
            }

        },
	},
	components: {
	},
};
</script>

<style scoped>
.o-text-sm {
  font-size: 70%;
}

.o-text-lg {
  font-size: 130%;
}
.o-text-xlg {
  font-size: 150%;
}
</style>
