<template>
    <div>
        <Toast />
        <Accordion @tab-open="search_open = true" @tab-close="search_open = false"  :activeIndex="search_open ? 0 : -1">

            <AccordionTab :active="search_open">
                <template slot="header">
                    <span>Search</span>
                </template>
                <SearchTable select-icon="pi pi-search" @row-selected="setSearch" :order="order" :lot="lot" :product="product" :location="location"/>
            </AccordionTab>
        </Accordion>
        <loader v-if="searching" />

        <div class="p-grid p-mt-2">
            <div v-if="dataTimeline.length > 0" class="p-col-12">
                <h1><strong>{{selected_row ? selected_row.hr : ''}}</strong></h1>
                <hr>
                <div class="p-grid">
                    <div class="p-col-8">
                        <Timeline :value="dataTimeline" align="left">
                            <template #marker="slotProps">
                                <div class="custom-marker p-d-flex p-ai-center p-jc-center" >
                                    <i :class="getStepIcon(slotProps.item.bizStep.split(':')[4])"></i>
                                </div>
                            </template>
                            <template #content="slotProps">
                                <Card>
                                    <template #title>
                                        <span>
                                            <Button icon="pi pi-map-marker" @click="() => {map_center = [slotProps.item.readPointLongitude, slotProps.item.readPointLatitude]}" class="p-mt-2 dot" />
                                            {{slotProps.item.bizStep.split(':')[4].toUpperCase()}}
                                        </span>
                                    </template>
                                    <template #subtitle>
                                        {{new Date(slotProps.item.eventTime.value).toLocaleString()}}
                                        <Chip :label="slotProps.item.disposition.split(':')[4].replace('_', ' ').toUpperCase()" class="p-ml-2" /> 
                                    </template>
                                    <template #content>
                                        <AuditView :eventid="slotProps.item.eventId" class="p-mt-2"/>
                                        <span class="o-text-sm">{{slotProps.item.eventId}}</span>
                                    </template>
                                </Card>
                            </template>
                        </Timeline>
                    </div>
                    <div class="p-col-4">
                        <Mapgl v-if="!searching" :events="dataTimeline" :center="map_center"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchTable from "../components/SearchTable"
import Mapgl from "../components/Map.vue";
import loader from "../components/loader.vue";
import ApiService from "../service/ApiService";
import ProductService from "../service/ProductService";
import AuditView from "../components/AuditView.vue";

export default {
    name: "App",
    components: {
        SearchTable,
        AuditView,
        Mapgl,
        loader,
    },
    data() {
        return {
            messageWhenNoItems: "There are no items to display",
            dataTimeline: [],
            ApiService: null,
            show: false,
            search_product: '',
            searching: false,
            search_open: true,
            dateRange: [],
            materials: [],
            lots: [],
            loading_lots: false,
            filters: {},
            selected_row: null,
            ProductService: null,
            map_center: []
        }
    },
    props: {
        order: {
            type: String,
            default: ''
        },
        lot: {
            type: String,
            default: ''
        },
        product: {
            type: String,
            default: ''
        },
        location: {
            type: String,
            default: ''
        }
    },
    created() {
        this.ApiService = new ApiService();
        this.productService = new ProductService(this.$auth.accessToken);
        this.$auth.eventBus.$on("access_token_refreshed", () => {
            this.productService.updateToken(this.$auth.accessToken);
        });
    },
    destroyed(){
        this.$auth.eventBus.$off("access_token_refreshed");
    },
    methods: {
        displayAuditStatus(a) {
            let success = false;
            let fail = false;


            a.metrics.forEach((m) => {
                if (m.score >= m.required_score){
                    success = true;
                }else {
                    fail = true;
                }
            });

            if (success && fail) {
                return 'warning';
            }
            else if (success) {
                return 'ok';
            }
            else if (fail) {
                return 'alert';
            }

        },
        setSearch(row) {
            if (!row.epc){
                this.dataTimeline = [];
            }else {
                this.selected_row = row;
                this.searching = true;
                this.dataTimeline = [];
                this.auditTimeline = [];
                this.productService.getFromEp('verify_get_uid_events', {uri: row.epc}).then(data => {
                    if (!data) {
                        this.$toast.add({severity:'info', summary: "No results", detail:`No results were returned. Please consider removing search constraints.`, life: 5000});
                        this.rows = [];
                    }
                    else {
                        this.dataTimeline = data;
                    }
                    this.searching = false;
                });
            }
        },
        getStepIcon(step){
            switch(step){
                case 'receiving' : 
                    return 'fa fa-bullseye';
                case 'shipping' : 
                    return 'fa fa-truck';
                case 'packing' : 
                    return 'fa fa-th-large';
                case 'commissioning':
                    return 'pi pi-plus';
                default: 
                    return 'pi pi-circle-off'
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.p-text-uppercase {
    text-transform: uppercase!important;
}

.p-text-capitalize {
    text-transform: capitalize!important;
}

.p-d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.o-full-w {
    width: 100%;
}

.o-timeline-container{
    height: 700px;
    overflow-y: scroll;
}


.custom-marker {
    border: 1px solid #DEE2E6;
    height: 30px;
    max-height: 30px;
    width: 30px;
    max-width: 30px;
    border-radius: 15px;
    color: #DEE2E6;
    background-color: #FFFFFF;
}

.dot {
    width: 30px;
    height: 30px;
    border-radius: 15px !important;
}

/deep/ .p-timeline-event-opposite {
    max-width: 0px;
    padding: 0px !important;
}
/deep/ .p-chip {
    border-radius: 33px !important;
    font-size: 75%;
}
/deep/ .p-chip-icon {
    font-size: 85%;
}
</style>
