<template>
    <div id="mapContainer" class="card basemap"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
    name: "map-gl",
    props: {
        events :{
            type: Array,
            default: () => ([])
        },
        center: []
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPGL_TOKEN,
            map: null,
            marker_list: [],
            marker: null,
            route: null,
            last_center: [],
        };
    },
    mounted() {
        mapboxgl.accessToken = this.accessToken;
        this.map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [this.events[0].readPointLongitude, this.events[0].readPointLatitude],
            zoom: 3.5,
        });
        this.last_center = this.center;

        // https://github.com/mapbox/mapbox-gl-js/issues/2268#issuecomment-401979967
        this.map.on('load', () => {
            const waiting = () => {
                if (!this.map.isStyleLoaded()) {
                    setTimeout(waiting, 200);
                } else {
                    this.addLayers();
                }
            };
            waiting();
        });
        
        
    },
    watch: {
        center: function (){
            this.flyToCenter();
        }
    },
    methods: {
        flyToCenter() {
            this.map.flyTo({
                center: [
                    this.center[0],
                    this.center[1]
                ],
                essential: true
            });
            this.last_center = this.center;
        },
        addLayers() {
            // Map locations to an object to deduplicate location markers and generate routes
            // Events are ordered by event time on the server side
            let t_events = {};
            this.events.forEach((event) => {
                t_events[event.readPointLocation] = {lon:event.readPointLongitude,lat:event.readPointLatitude};
            });
            
            
            Object.keys(t_events).map((location) => {
                this.marker_list.push(new mapboxgl.Marker()
                .setLngLat([t_events[location].lon, t_events[location].lat])
                .setPopup(new mapboxgl.Popup().setHTML(`<h1>${location}</h1>`))
                .addTo(this.map));
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.basemap {
    width: 100%;
    height: 80vh;
}
</style>