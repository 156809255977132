<template>
    <div>
        <Toast />

        <DataTable :value="rows" :lazy="false" :paginator="true" :rows="10" dataKey="epc" :selection.sync="selectedRow" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :totalRecords="totalRecords"
        :rowHover="true" :loading="loading" :rowsPerPageOptions="[10,25,50]" >
            <template #empty>
                Nothing to display. Please select a lot above and click <i class="fas fa-sync"></i>
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
            <template #header>
                <div class="table-header">
                    <div class="p-fluid p-mb-2">
                        <MultiSelect v-model="filters['selected_products']" :options="product_list" optionLabel="Name" placeholder="Filter by Product" display="chip" :filter="true"/>
                    </div>
                    <div class="p-fluid">
                        <Dropdown v-model="filters['selected_lot']" :options="filtered_lot_list" optionLabel="LotNumber" placeholder="Filter by Lot" :filter="true"/>
                    </div>
                </div>
            </template>
            <Column headerStyle="width: 45px; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #filter>
                    <Button type="button" icon="fas fa-sync" @click="findRecords(false)" :disabled="loading || !(filters['selected_lot'] && filters['selected_lot'].LotNumber)"/>
                </template>
                <template #body="slotProps">
                    <Button type="button" :icon="selectIcon" @click="selectRow(slotProps.data)"></Button>
                </template>
            </Column>
            <Column bodyStyle="text-align: center" field="hr" header="UID" >
                <template #body="slotProps">
                    <div>
                         {{slotProps.data.hr}}
                    </div>
                    <div class="o-text-sm">
                        {{slotProps.data.epc}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['hr']" class="p-column-filter" placeholder="Search by UID" @input="filter()"/>
                </template>
            </Column>
            <Column bodyStyle="text-align: center" field="bizStepHr" header="BizStep" >
                <template #body="slotProps">
                    <div>
                         {{slotProps.data.bizStepHr}}
                    </div>
                    <div class="o-text-sm">
                        {{slotProps.data.bizStep}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['bizStepHr']" class="p-column-filter" placeholder="Search by bizStep" @input="filter()"/>
                </template>
            </Column>
            <Column bodyStyle="text-align: center" field="dispositionHr" header="Disposition"  >
                <template #body="slotProps">
                    <div>
                         {{slotProps.data.dispositionHr}}
                    </div>
                    <div class="o-text-sm">
                        {{slotProps.data.disposition}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['dispositionHr']" class="p-column-filter" placeholder="Search by Disposition" @input="filter()"/>
                </template>
            </Column>
            <Column bodyStyle="text-align: center" field="readPointLocation" header="Location" >
                <template #filter>
                    <InputText type="text" v-model="filters['readPointLocation']" class="p-column-filter" placeholder="Search by Location" @input="filter()"/>
                </template>
            </Column>
        </DataTable>
    </div>    
</template>

<script>
import moment from "moment";
import ApiService from "../service/ApiService";
import ProductService from "../service/ProductService";

export default {
    name: "SearchTable",
    components: {
    },
    props: {
        selectIcon: {
            type: String,
            default: ""
        },
        product: {
            type: String,
            default: ""
        },
        lot: {
            type: String,
            default: ""
        },
        order: {
            type: String,
            default: ""
        },
        location: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            searchmode: "startswith",
            selectedRow: null,
            ApiService: null,
            ProductService: null,
            rows: [],
            totalRecords: 0,
            num_rows: 10,
            page: 0,
            loading: true,
            filters: {},
            product_list: [],
            lot_list: []
        }
    },
    created() {
        this.ApiService = new ApiService();
        this.productService = new ProductService(this.$auth.accessToken);
        this.$auth.eventBus.$on("access_token_refreshed", () => {
            this.productService.updateToken(this.$auth.accessToken);
        });
    },
    destroyed(){
        this.$auth.eventBus.$off("access_token_refreshed");
    },
    mounted() {
        this.getProducts();
        //this.preSearch();
    },
    computed: {
        filtered_lot_list() {
            return this.lot_list.filter(ll => {
                if (this.filters.selected_products?.length) {
                    return this.filters.selected_products.find(p => p.MainReference === ll.reference);
                }
                return true;
            })
        }
    },
    methods: {        
        dpCountryConvert(c) {
            const cc = {
                'brazil': 'Brazil',
                'canada': 'Canada',
                'usa': 'United States',
                'uk': 'United Kingdom',
                'india': 'india',
                'uganda': 'uganda',
                'namibia': 'namibia',
                'drc': 'congo',
                'zambia': 'zambia',
                'rwanda': 'rwanda',
            }
            return cc[c];
        },
        roleToCountry(){
            const countries = [];
            if (this.$auth.user.allRoles.includes('dashboard-country-rw')) countries.push('rwanda');
            if (this.$auth.user.allRoles.includes('dashboard-country-na')) countries.push('namibia');
            if (this.$auth.user.allRoles.includes('dashboard-country-drc')) countries.push('drc');
            if (this.$auth.user.allRoles.includes('dashboard-country-za')) countries.push('zambia');
            if (this.$auth.user.allRoles.includes('dashboard-country-ug')) countries.push('uganda');
            if (this.$auth.user.allRoles.includes('dashboard-country-ca')) countries.push('canada');
            if (this.$auth.user.allRoles.includes('dashboard-country-usa')) countries.push('usa');
            if (this.$auth.user.allRoles.includes('dashboard-country-br')) countries.push('brazil');
            if (this.$auth.user.allRoles.includes('dashboard-country-in')) countries.push('india');
            return countries.map(c => this.dpCountryConvert(c));
        },
        filter(){
            this.rows = this.allRows
            .filter(t => {
                if (this.filters['hr']) {
                    return t.hr.includes(this.filters['hr']);
                }
                return true;
            })
            .filter(t => {
                if (this.filters['bizStepHr']) {
                    return t.bizStepHr.includes(this.filters['bizStepHr']);
                }
                return true;
            })
            .filter(t => {
                if (this.filters['dispositionHr']) {
                    return t.dispositionHr.includes(this.filters['dispositionHr']);
                }
                return true;
            })
            .filter(t => {
                if (this.filters['readPointLocation']) {
                    return t.readPointLocation.includes(this.filters['readPointLocation']);
                }
                return true;
            })
        },
        checkDigitGtin(uri, plain_gtin) {
            const c = uri.split(':')[4];
            const sn = c.split('.')[2];
            let input = c.split('.')[1][0] + c.split('.')[0] + c.split('.')[1].slice(1);
            let array = input.split('').reverse();

            let total = 0;
            let i = 1;
            array.forEach(number => {
                number = parseInt(number);
                if (i % 2 === 0) {
                    total = total + number;
                }
                else
                {
                    total = total + (number * 3);
                }
                i++;
            });
            const gtin = input + ((Math.ceil(total / 10) * 10) - total);
            if (plain_gtin) {
                return gtin;
            }
            return '(01)' + gtin + (sn ? ('(21)' + sn) : '');
        },
        getProducts(){
            this.productService.getFromEp('verify_products').then(data => {
                if (!data) {
                    this.$toast.add({severity:'info', summary: "No product lots yet", detail:`No product lots found. You will not be able to use the lot filter`, life: 5000});
                    this.product_list = [];
                }
                else {
                    this.product_list = data.map(p => {
                        p.MainReference = p.References[0];
                        p.gtin = this.checkDigitGtin(p.References[0], true);
                        return p;
                    }).filter(p => p.IsBaseUnit);
                    this.getLots();
                }
            });
        },
        getLots(){
            this.productService.getFromEp('verify_lots', {refs: this.product_list.map(p => p.MainReference), countries: this.roleToCountry()}).then(data => {
                if (!data) {
                    this.$toast.add({severity:'info', summary: "No product lots yet", detail:`No product lots found. You will not be able to use the lot filter`, life: 5000});
                    this.lot_list = [];
                }
                else {
                    this.lot_list = data;
                }
                this.loading = false;
            });            
        },
        onPage(event){
            this.page = event.page;
            this.num_rows = event.rows;
            this.findRecords();
        },
        findRecords(){
            this.loading = true;
            this.productService.getFromEp('verify_get_uids', {lot: this.filters['selected_lot'].LotNumber, countries: this.roleToCountry()}).then(data => {
                if (!data) {
                    this.$toast.add({severity:'info', summary: this.$t("No results"), detail: this.$t(`No results were returned. Please consider removing search constraints.`), life: 5000});
                    this.allRows = [];
                }
                else {
                    this.totalRecords = data[0].total;
                    this.allRows = data[0].Uids.map(u => {
                        if (u) {
                            u.hr = this.checkDigitGtin(u.epc);
                            u.bizStepHr = u.bizStep.split(':')[4].replace('_', ' ');
                            u.dispositionHr = u.disposition.split(':')[4].replace('_', ' ');
                            return u;
                        }
                    });
                    this.filter()
                }
                this.loading = false;
            });
        },
        removeDuplicates(dataObject) {
          let seen = []
          let tempData = []
          dataObject.forEach((item) => {
            if (seen.indexOf(item.bizStep) === -1) {
              seen.push(item.bizStep)
              tempData.push(item)
            }
          })
          return tempData
        },
        
        selectRow(row) {
            // this.search_toggle = true;
            this.$emit("row-selected", row);
        },
        getFormattedDate(timestamp) {
            return `${moment.utc(timestamp).format("D MMMM, HH:mm:ss")}`;
        },
        preSearch() {
            if(Boolean(this.order) || Boolean(this.product) || Boolean(this.lot) || Boolean(this.location)) {
                this.findRecords(true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>