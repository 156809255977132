<template>
    <div class="loader">
        <div class="bar"></div>
    </div>
</template>

<script>
export default {
    name: "loader",
}
</script>

<style lang="scss" scoped>
@keyframes loader-animation {
  0% {
    width: 0%;
  }
  49% {
    width: 100%;
    left: 0%;
  }
  50% {
    left: 100%;
    width: 0%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}
.loader {
  height: 3px;
  width: 100%;
}
.loader .bar {
  position: relative;
  height: 3px;
  background-color: #188fa7;
  animation-name: loader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
</style>
